import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import sewer_suction from "../../public/static/images/products/sewer_suction.jpg"

function SewerSuction() {

  const prodDetail = {
    "name": "SEWER SUCTION",
    "detail": {
      "about" : ["Sewer Suction cum jetting machine is used for cleaning of sewer lines by sucking out the dirt from chamber into its tank as well as clear the blockage / choke of sewer lines by high capacity jetting throw of water."],
      "advantages": [
        "Suction Machine is used for cleaning of sewer lines by sucking out the dirt /dust/garbage /other impurities directly from chamber in to its vacuum tank.",
        "Jetting Machine is used to clear the blockage / sewer lines by high capacity jet throw of liquid that is store in its freshwater tank.",
        "It’s easy operations, low maintenance & high capacity Suction-jetting System helps in superior cleaning of sewer lines with high productivity & in very less time.",
        "Provision for manufacturing Suction and vacuum tank separately or combined on single vehicle."
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Chassis",
        "Tank Size",
        "Sheet Material",
        "Thickness",
        "Vacuum Pump",
        "Jetting Pump",
        "Hoses",
        "Power Take Off",
        "Hydraulic Cylinder",
        "Hydraulic Pump",
        "Hydraulic Motor"
      ],
      "column2": [
        "IESS - M/SJM/SSJM",
        "7 Ton, 9 Ton, 12 Ton, 16 Ton, 25 Ton/trailer or Custmize etc.",
        "4000 Ltrs, 6000 Ltrs, (8-20 Kl or Customized Tanks)",
        "Mild Steel (IS : 2062)",
        "5 Mm, 6 Mm or as Per Requirement",
        "M/s Jurop, Italy / Battioni Pagani Italy / Eq. (5000 LPM –13000 LPM Capacity)",
        "M/s Pratissoli, Italy / Equivalent Make ( 70 –260 LPM) / (90 –150 Bar) Capacity Suction",
        "Hose Heavy Duty (upto 5” & 15 Mtrs) Jetting House High Pressure (upto1¼”&60mtrs) Washing Hose With Gun (½”&10mtrs) Optional",
        "Power Take Off",
        "1 Nos. For Tank Tipping & 2 Nos. For Door Opening",
        "Gear Pump (40-80 LPM)",
        "60-160 CC"
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={sewer_suction} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default SewerSuction
